import styled, { keyframes } from "styled-components";

const translate = keyframes`
0% {
    opacity: 0;
    transform: translateY(40px);
}
100% {
    opacity: 1;
    transform: translateY(0);
}
`;

export const ToastContainer = styled.div`
  position: absolute;
  top: -63px;

  .triangle {
    position: absolute;
    margin-left: 205px;
    margin-bottom: 60px;
    margin-top: -2px;
    width: auto;
    transform: rotateX(180deg);
    margin-right: 10px;
  }
  .toast {
    display: grid;
    grid-template-columns: 246px;
    margin-top: -8px;

    animation: ${translate} 0.5s;
    width: 246px;
    height: 52px;
    justify-items: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${props => props.theme.backgroundDark};
    cursor: pointer;

    p {
      font-size: ${props => props.theme.p2r.fs};
    }
  }
`;
export const Icon = styled.img`
  justify-self: end;
  transform: rotate(90deg);
`;
