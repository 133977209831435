import gql from "graphql-tag";

const fragments = {
  order: gql`
    fragment OrderData on Order {
      id
      user
      project {
        id
      }
      licenseType
      level
      discountCode
      license
      price
      status
      stripeChargeId
      receipt
      discount {
        id
        percent
      }
      cartItems {
        id
        track {
          id
        }
      }
      created_at
      updated_at
    }
  `,
  discount: gql`
    fragment DiscountData on Discount {
      id
      code
      percent
      active
      description
    }
  `
};

export const CREATE_INITIAL_ORDER = gql`
  mutation CreateOrder($licenseType: LicenseType!, $type: OrderType!) {
    createOrder(data: { licenseType: $licenseType, level: $type }) {
      id
      level
    }
  }
`;

export const GET_USER_SUBSCRIPTIONS = gql`
  query($userId: ID!) {
    userSubscriptions(user: $userId) {
      id
      planId
      subscriptionId
      user
      enterprise
      status
      amountOutstanding
      license
      licenseType
      level
      last4
      team {
        id
        name
        image
        description
      }
      project {
        id
        name
        description
        image
        team {
          id
          name
          image
          description
        }
      }
      nameOnLicense
    }
  }
`;

export const GET_PAYMENTS = gql`
  query payments {
    payments {
      id
      user {
        id
      }
      order {
        id
        license
        price
        customerName
        customerCardLast4
        customerCardBrand
        tracks {
          id
          title
          variationTitle
          name
          album {
            id
          }
          thumbnail
          waveform
          status
          trackOrder
          duration
          stem {
            name
            mp3File
            wavFile
          }
          key
          bpm
          signature
          initialBeat
          ensemble
          mood
          gravity
          energy
          melody
          tension
          rhythm
          density
          isrc
          publisherId
          publisher
          publishDate
          proAffiliation
          price
          systemTags
          tags
          instruments
          variation
          updated
          freeStemFiles
          type
          blockFile {
            name
            mp3File
            wavFile
          }
          description
          parentTrackId
          mp3File
          fileMetaData
          variationTracks {
            id
          }
        }
      }
      subscription {
        id
        license
        price
        customLicenseStatement
        summary
        interval
        intervalCount
      }
      project {
        id
        description
      }
      projectName
      receipt
      license
      created_at
      updated_at
    }
  }
`;
export const GET_ORDERS = gql`
  query($userId: ID!) {
    userOrders(user: $userId) {
      id
      licenseType
      level
      discountCode
      license
      price
      receipt
      project {
        name
        id
      }
      tracks {
        id
        title
        variationTitle
        name
        album {
          id
        }
        thumbnail
        waveform
        status
        trackOrder
        duration
        stem {
          name
          mp3File
          wavFile
        }
        key
        bpm
        signature
        initialBeat
        ensemble
        mood
        gravity
        energy
        melody
        tension
        rhythm
        density
        isrc
        publisherId
        publisher
        publishDate
        proAffiliation
        price
        systemTags
        tags
        instruments
        variation
        updated
        freeStemFiles
        type
        blockFile {
          name
          mp3File
          wavFile
        }
        description
        parentTrackId
        mp3File
        fileMetaData
        variationTracks {
          id
        }
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const VALIDATE_ORDER_DISCOUNT_CODE = gql`
  query Discount($discountCode: String!) {
    discount(code: $discountCode) {
      ...DiscountData
    }
  }
  ${fragments.discount}
`;

export const APPLY_DISCOUNT_CODE = gql`
  mutation OrderDiscount($id: ID!, $discountCode: String!) {
    orderDiscount(id: $id, discountCode: $discountCode) {
      ...OrderData
    }
  }
  ${fragments.order}
`;

export const REMOVE_ITEM = gql`
  mutation DeleteOrderItem($id: ID!, $orderId: ID!) {
    deleteOrderItem(id: $id, orderId: $orderId)
  }
`;

export const ADD_ORDER_ITEM = gql`
  mutation CreateOrderItem($orderId: ID!, $detail: [OrderItemDetailInput!]!) {
    createOrderItems(data: { orderId: $orderId, detail: $detail }) {
      id
      price
      track {
        id
        name
      }
      album {
        id
        name
      }
    }
  }
`;

export const SUBSCRIPTION = gql`
  mutation Subscription(
    $token: String
    $email: String!
    $fullName: String!
    $nameOnLicense: String
    $projectName: String!
    $projectUrl: String
    $description: String
    $planId: String!
    $type: SubscriptionType!
    $licenseType: SubscriptionLicenseType!
    $subId: ID
    $coupon: String
    $projectId: ID
  ) {
    subscription(
      data: {
        token: $token
        email: $email
        fullName: $fullName
        nameOnLicense: $nameOnLicense
        projectName: $projectName
        projectUrl: $projectUrl
        description: $description
        projectId: $projectId
        planId: $planId
        licenseType: $licenseType
        level: $type
        subId: $subId
        coupon: $coupon
      }
    ) {
      id
      status
    }
  }
`;

export const PAYMENT = gql`
  mutation OrderPayment(
    $orderId: ID
    $licenseType: LicenseType!
    $level: OrderType!
    $items: [OrderItemDetailInput!]!
    $discountCode: String
    $price: Float!
    $token: String
    $email: String!
    $fullName: String!
    $nameOnLicense: String
    $projectName: String!
    $projectUrl: String
    $description: String
    $projectId: ID
  ) {
    orderPayment(
      data: {
        orderId: $orderId
        licenseType: $licenseType
        level: $level
        items: $items
        discountCode: $discountCode
        price: $price
        token: $token
        email: $email
        fullName: $fullName
        nameOnLicense: $nameOnLicense
        projectName: $projectName
        projectUrl: $projectUrl
        description: $description
        projectId: $projectId
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $type: OrderType!
    $licenseType: LicenseType!
    $id: ID!
  ) {
    updateOrder(data: { level: $type, licenseType: $licenseType }, id: $id) {
      ...OrderData
    }
  }
  ${fragments.order}
`;

export const VALIDATE_COUPON = gql`
  query SubscriptionDiscount($name: String!) {
    subscriptionDiscount(name: $name) {
      id
      name
      amount_off
      duration
      duration_in_months
      percent_off
      valid
    }
  }
`;

export const REMOVE_DISCOUNT_CODE = gql`
  mutation RemoveDiscount($order: ID!) {
    removeDiscount(order: $order) {
      id
    }
  }
`;
