import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

const Upload = lazy(() => import("../../pages/Admin/upload"));
const Reindex = lazy(() => import("../../pages/Admin/reindex"));
const NotFound = lazy(() => import("../../components/404"));

/*
 * Add all routes specific to the admin here
 * e.g., /admin/path
 */
const RoutesAdmin = ({ root }) => {
  return (
    <Switch>
      <Route path={`${root}/upload`} component={Upload} />
      <Route path={`${root}/reindex`} component={Reindex} />
      <Redirect exact from="/admin" to="/admin/upload" />
      <Route component={NotFound} />
    </Switch>
  );
};

export default RoutesAdmin;
