import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback
} from "react";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router-dom";

import QueuePane from "./QueuePane";
import Waveform from "../Waveform";
import Toast from "../Toast/index";
import useModal from "../../hooks/useModal";
import DownloadModal from "../DownloadModal";
import CreateEditPlaylistModal from "../CreateEditPlaylistModal";
import AddToPlaylistModal from "../AddToPlaylistModal";

import { QueueContext } from "../../context/QueueProvider";
import { AuthContext } from "../../context/AuthProvider";
import { CartContext } from "../../context/CartContext/CartProvider";
import { SearchContext } from "../../context";
import { FavoritesContext } from "../../context";

import { Container, MobileContainer, FavoriteSVG } from "./styles";

import pause from "../../assets/pause.svg";
import play from "../../assets/play.svg";
import playNext from "../../assets/play_next.svg";
import playPrevious from "../../assets/play_previous.svg";
import playlist from "../../assets/queue.svg";
import actions from "../../assets/actions-bold.svg";
import cart from "../../assets/cart.svg";
import download from "../../assets/download.svg";
import addToPlaylist from "../../assets/playlist-large.svg";
import favorite from "../../assets/favorite.svg";
import playlistCloseMobile from "../../assets/remove-track.svg";
import editorAdmin from "../../assets/editor-admin-open.svg";
import fullCart from "../../assets/full-cart.svg";
import { theme } from "../../globalStyles";
import EditorPopoverButton from "../EditorPopoverButton";
import { EditorContext } from "../../editor/context/EditorContext/EditorProvider";
import { MasterVolumeControl } from "../MasterVolumeControl";

// !The <audio> tag used to play the app's audio is at the bottom of this component

const Player = props => {
  const [currentTime, setCurrentTime] = useState(0);
  const [playerActionsPosition, setPlayerActionsPosition] = useState(0);
  const [queueToastPosition, setqueueToastPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playlistVisible, setPlaylistVisible] = useState(false);
  const [mobilePlaylistVisible, setMobilePlaylistVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [lastPlayedTrack, setLastPlayedTrack] = useState();
  const [tracksToSave, setTracksToSave] = useState();

  const { isFoundInCart, addCartItem, removeCartItem } = useContext(
    CartContext
  );
  const search = useContext(SearchContext);
  const fav = useContext(FavoritesContext);
  const playlistEl = useRef();
  const audioPlayerEl = useRef(null);
  const isInitialMount = useRef(true);
  const addtoQueuePosition = useRef();
  const playerActionsRef = useRef();
  const queue = useContext(QueueContext);
  const auth = useContext(AuthContext);
  const editor = useContext(EditorContext);

  const [currentTrack, setCurrentTrack] = useState(
    queue.currentTrack && queue.currentTrack.track
      ? queue.currentTrack.track
      : null
  );

  ///// MODALS
  const downloadModal = useModal();
  const createEditPlaylistModal = useModal();
  const addToPlaylistModal = useModal();

  // Media Query for Mobile version
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 800 });

  const togglePlay = useCallback(() => {
    // qeusetPlaying(!playing);
    queue.setPlaying(!queue.playing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.playing]);

  const handlePressSpace = useCallback(
    event => {
      event.stopPropagation();

      if (
        event.keyCode === 32 &&
        event.currentTarget.activeElement.tagName !== "INPUT" &&
        event.currentTarget.activeElement.tagName !== "TEXTAREA"
      ) {
        event.preventDefault();
        togglePlay();
      }
    },
    [togglePlay]
  );

  // finding the More Actions (or the ...) icon's position
  useEffect(() => {
    const playerActions = playerActionsRef.current;
    if (playerActions && !playerActionsPosition) {
      setPlayerActionsPosition(playerActions.getBoundingClientRect().right);

      window.addEventListener("resize", () =>
        setPlayerActionsPosition(playerActions.getBoundingClientRect().right)
      );
    }

    return () => {
      window.removeEventListener("resize", () =>
        setPlayerActionsPosition(playerActions.getBoundingClientRect().right)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsVisible]);

  // finding the Queue pane icon's position
  useEffect(() => {
    const playlistToggle = addtoQueuePosition.current;
    playlistToggle &&
      setqueueToastPosition(playlistToggle.getBoundingClientRect().left);

    if (playlistToggle && !queueToastPosition) {
      window.addEventListener("resize", () =>
        setqueueToastPosition(playlistToggle.getBoundingClientRect().left)
      );
    }

    return () => {
      window.removeEventListener("resize", () =>
        setqueueToastPosition(playlistToggle.getBoundingClientRect().left)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistVisible, queue.addToQueueMessage]);

  // update play/pause based on Queue Context
  useEffect(() => {
    const currentAudioPlayer = audioPlayerEl.current;
    queue.playing
      ? currentAudioPlayer.play().catch(err => {
        queue.setPlaying(false);
      })
      : currentAudioPlayer.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.playing]);

  useEffect(() => {
    if (queue.seeked) {
      const currentAudioPlayer = audioPlayerEl.current;
      if (currentAudioPlayer.readyState >= 1) {
        currentAudioPlayer.currentTime =
          currentAudioPlayer.duration * (queue.percentagePlayed / 100);

        queue.setPlaying(true);
        queue.setSeeked(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.seeked, queue.percentagePlayed]);

  // Event listener to close the QueuePane
  // Also scrolls to the queue section in the QueuePane
  useEffect(() => {
    if (playlistVisible) {
      document.addEventListener("mousedown", closePlaylist);

      const queueSection = document.getElementById("queue-section");
      queueSection.scrollIntoView(true);
    } else {
      document.removeEventListener("mousedown", closePlaylist);
    }

    return () => {
      document.removeEventListener("mousedown", closePlaylist);
    };
  }, [playlistVisible]);

  // MobileView - Scroll to the queue section in the QueuePane
  useEffect(() => {
    if (mobilePlaylistVisible) {
      const queueSection = document.getElementById("queue-section");
      queueSection.scrollIntoView(true);
    }
  }, [mobilePlaylistVisible]);

  // Start playing if a track has changed
  useEffect(() => {
    const currentAudioPlayer = audioPlayerEl.current;
    queue.playing &&
      currentAudioPlayer.play().catch(err => {
        queue.setPlaying(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.playing, queue.currentTrack.track]);

  // Play/Pause on pressing Spacebar
  useEffect(() => {
    if (!editor.drawerState.visible) {
      document.addEventListener("keydown", handlePressSpace);
    } else {
      document.removeEventListener("keydown", handlePressSpace);
    }

    return () => {
      document.removeEventListener("keydown", handlePressSpace);
    };
  }, [handlePressSpace, queue.playing, editor.drawerState.visible]);

  // Storing the last played track for use incase of any encountered failure.
  useEffect(() => {
    queue.setAudioLoaded(false);
    if (queue.currentTrack.track) {
      let newCurrentTrack = { ...queue.currentTrack.track };
      setLastPlayedTrack(newCurrentTrack);
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!editor.drawerState.visible) {
      queue.setPlaying(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.currentTrack.track]);

  useEffect(() => {
    setCurrentTrack(queue.currentTrack.track);
  }, [queue.currentTrack.track]);

  const closePlaylist = e => {
    if (playlistEl.current && playlistEl.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setTimeout(() => setPlaylistVisible(false), 100);
  };

  const setCurrentTimeAndDuration = () => {
    const currentAudioPlayer = audioPlayerEl.current;

    setCurrentTime(currentAudioPlayer.currentTime);
    setDuration(currentAudioPlayer.duration);
  };

  const showTime = () => {
    queue.setAudioLoaded(true);
    const currentAudioPlayer = audioPlayerEl.current;

    // Not showing track duration if audio metadata is not retrieved
    if (currentAudioPlayer.readyState === 0) {
      /* 
        When the audio file src is changed set the seekbar(percentagePlayed) back to 0.
        audioDOM's readyState is 0 before the audio file is retrieved. Using this as an indication that the track has been changed
      */
      // queue.setPercentagePlayed(0);
      return;
    }

    setCurrentTimeAndDuration();

    let percent =
      (currentAudioPlayer.currentTime / currentAudioPlayer.duration) * 100;
    queue.setPercentagePlayed(percent);
  };

  const playNextAndResetProgress = () => {
    queue.playlist[queue.currentTracker + 1] && queue.playNext();
    setCurrentTime(0);
    queue.playlist[queue.currentTracker + 1] || queue.setPlaying(false);
  };

  return (
    <>
      {isDesktopOrLaptop ? (
        <Container
          playerActionsPosition={playerActionsPosition}
          queuePanePosition={queueToastPosition}
        >
          {queue.addToQueueMessage && (
            <div className="toast-container">
              <div
                className="toast"
                style={{
                  position: "relative",
                  left: queueToastPosition - 205
                }}
              >
                <Toast openQueue={() => setPlaylistVisible(true)} />
              </div>
            </div>
          )}
          <div className="player-container">
            {/* Thumbnail + Track title + Album Name */}
            <div className="details">
              <img
                className="thumbnail"
                src={
                  queue.currentTrack &&
                  queue.currentTrack.track &&
                  queue.currentTrack.track.thumbnail
                }
                alt="Album cover"
              />
              <div
                className="title"
                onClick={() => {
                  props.history.push(
                    `/browse/track/${queue.currentTrack &&
                    queue.currentTrack.track &&
                    queue.currentTrack.track.id}`
                  );
                }}
              >
                {queue.currentTrack &&
                  queue.currentTrack.track &&
                  queue.currentTrack.track.title}
              </div>
              <div
                className="album"
                onClick={() =>
                  props.history.push(
                    `/album/${queue.currentTrack &&
                    queue.currentTrack.track &&
                    (queue.currentTrack.track.album.id ||
                      queue.currentTrack.track.album)}`
                  )
                }
              >
                {queue.currentTrack.track ? (
                  <div>
                    {queue.currentTrack &&
                      queue.currentTrack.track &&
                      queue.currentTrack.track.variation ? (
                      <div className="album-name">
                        |{" "}
                        {queue.currentTrack &&
                          queue.currentTrack.track &&
                          queue.currentTrack.track.variationTitle}{" "}
                      </div>
                    ) : (
                      <div className="album-name">
                        {" "}
                        {queue.currentTrack &&
                          queue.currentTrack.track &&
                          (queue.currentTrack.track.albumName ||
                            queue.currentTrack.track.album.name)}
                      </div>
                    )}{" "}
                  </div>
                ) : (
                  lastPlayedTrack &&
                  lastPlayedTrack.track &&
                  lastPlayedTrack.track.variationTitle
                )}
              </div>
            </div>

            {/* Previous, Play and Next buttons */}
            <div className="playback-controls">
              <img
                src={playPrevious}
                onClick={
                  queue.playlist[queue.currentTracker - 1] && queue.playPrevious
                }
                alt=""
                className={`change-track ${queue.playlist[queue.currentTracker - 1]
                    ? "active-control"
                    : ""
                  }`}
              />
              {queue.playing ? (
                <img
                  src={pause}
                  alt="pause"
                  onClick={togglePlay}
                  className="toggle-playback"
                />
              ) : (
                <img
                  src={play}
                  alt="play"
                  onClick={togglePlay}
                  className="toggle-playback"
                />
              )}
              <img
                src={playNext}
                alt=""
                onClick={
                  queue.playlist[queue.currentTracker + 1] && queue.playNext
                }
                className={`change-track ${queue.playlist[queue.currentTracker + 1]
                    ? "active-control"
                    : ""
                  }`}
              />
            </div>
            {/* !The Waveform component's Grid Placing CSS is in the Component itself */}
            <Waveform isMainPlayer="main-player" />

            {/* Current time and duration of track */}
            <div className="duration">
              {`${Math.floor(currentTime / 60)}:${currentTime % 60 >= 10 ? "" : "0"
                }${Math.floor(currentTime % 60)} / ${Math.floor(duration / 60)}:${duration % 60 >= 10 ? "" : "0"
                }${Math.floor(duration % 60)}`}
            </div>

            {/* Sound and Queue control buttons */}
            <div className="extra-controls">
              <div className="volume">
                <MasterVolumeControl audioPlayerEl={audioPlayerEl} />
              </div>

              <div
                className={`playlist-button ${queue.queueTracker > 0 ? "active-playlist-button" : ""
                  }`}
                onClick={() => !playlistVisible && setPlaylistVisible(true)}
                ref={addtoQueuePosition}
              >
                <img src={playlist} alt="playlist" />
              </div>

              <EditorPopoverButton
                track={currentTrack}
                size={"21px"}
                accessibleColor={theme.highlight}
                inaccessibleColor={theme.textDark}
              />

              {auth.user.adminUser && (
                <div
                  className="editor-admin-button"
                  onClick={() => {
                    editor.requestTrackOpen(queue.currentTrack.track, false);
                  }}
                >
                  <img src={editorAdmin} alt="editor-admin-button" />
                </div>
              )}
            </div>

            {/* More actions (...) button */}
            <div
              className="actions"
              onMouseLeave={() => setActionsVisible(false)}
            >
              {actionsVisible && (
                <div
                  className="actions-popover"
                  onMouseLeave={() => setActionsVisible(false)}
                >
                  <div className="actions-wrapper">
                    {!auth.isUserPrivate() && (
                      <div
                        className="action-item"
                        onClick={() => {
                          if (isFoundInCart(queue.currentTrack.track)) {
                            const item = isFoundInCart(
                              queue.currentTrack.track
                            );
                            removeCartItem(item);
                            return;
                          }
                          addCartItem(queue.currentTrack.track, "Track");
                        }}
                      >
                        <img
                          src={
                            isFoundInCart(queue.currentTrack.track)
                              ? fullCart
                              : cart
                          }
                          alt=""
                        />
                        {isFoundInCart(queue.currentTrack.track)
                          ? "Remove from cart"
                          : "Add to Cart"}
                      </div>
                    )}

                    <div
                      className="action-item"
                      onClick={() => {
                        search.setDownloadTrack(queue.currentTrack.track);
                        downloadModal.open();
                      }}
                    >
                      <img src={download} alt="Download track" /> Download
                    </div>

                    <div
                      className="action-item"
                      onClick={() => {
                        queue.addToQueue(queue.currentTrack.track);
                      }}
                    >
                      <img src={playlist} alt="Add track to queue" />
                      Add to Queue
                    </div>

                    {auth.isUserPrivate() && (
                      <>
                        <div
                          className="action-item"
                          onClick={() => {
                            if (queue.currentTrack.track.favorite) {
                              const favTrack = fav.favorites.filter(
                                favorite => {
                                  if (
                                    favorite.track.id ===
                                    queue.currentTrack.track.id
                                  ) {
                                    return favorite;
                                  } else {
                                    return null;
                                  }
                                }
                              );

                              fav.removeFromFavorites(
                                queue.currentTrack.track.id
                              );
                              fav.removeTrackFromList(
                                queue.currentTrack.track,
                                favTrack.id
                              );
                              queue.currentTrack.track.favorite = false;
                            } else {
                              fav.addToFavorites(queue.currentTrack.track.id);
                              fav.addTrackToFavoritelist(
                                queue.currentTrack.track
                              );
                              queue.currentTrack.track.favorite = true;
                            }
                          }}
                        >
                          <FavoriteSVG
                            src={favorite}
                            activeicon={
                              queue.currentTrack.track.favorite
                                ? "true"
                                : undefined
                            }
                            alt="Add track to favorites"
                          />
                          Add to Favorites
                        </div>

                        <div
                          className="action-item"
                          onClick={addToPlaylistModal.open}
                        >
                          <img
                            src={addToPlaylist}
                            alt="Add track to playlist"
                          />
                          Add to Playlist
                        </div>
                      </>
                    )}
                  </div>
                  <div className="triangle">
                    <img
                      src={require("../../assets/triangle.svg")}
                      alt="Track Menu"
                    />
                  </div>
                </div>
              )}

              <img
                style={actionsVisible ? { opacity: 0.9 } : { opacity: 0.4 }}
                ref={playerActionsRef}
                src={actions}
                alt="Additional actions"
                onMouseEnter={() => setActionsVisible(true)}
              />
            </div>

            {/* THE QUEUE PANE */}
            {playlistVisible && (
              <QueuePane
                playlistEl={playlistEl}
                setTracksToSave={setTracksToSave}
                setPlaylistVisible={setPlaylistVisible}
                openModal={createEditPlaylistModal.open}
                queuePanePosition={queueToastPosition}
              />
            )}
          </div>

          {/* Create or Edit playlist modal  */}
          {createEditPlaylistModal.visible && (
            <CreateEditPlaylistModal
              modalState={createEditPlaylistModal}
              type="create"
              tracks={tracksToSave}
            />
          )}

          {/* Add track(s) to playlist modal  */}
          {addToPlaylistModal.visible && (
            <AddToPlaylistModal
              modalState={addToPlaylistModal}
              track={queue.currentTrack.track}
            />
          )}
        </Container>
      ) : (
        <MobileContainer
          hasAutoCompleteResults={search.hasAutoCompleteResults()}
        >
          {/* 1st ROW */}
          {queue.addToQueueMessage && (
            <div className="toast-container">
              <div
                className="toast"
                style={{
                  position: "relative",
                  left: queueToastPosition - 205
                }}
              >
                <Toast openQueue={() => setMobilePlaylistVisible(true)} />
              </div>
            </div>
          )}
          <div className="mobile-visualization">
            <div className="mobile-waveform">
              <Waveform isMainPlayer="main-player" />
            </div>
            <div className="playback-control">
              {queue.playing ? (
                <img
                  src={pause}
                  alt="pause"
                  onClick={togglePlay}
                  className="toggle-playback"
                />
              ) : (
                <img
                  src={play}
                  alt="play"
                  onClick={togglePlay}
                  className="toggle-playback"
                />
              )}
            </div>
          </div>
          <div className="details-and-queue">
            {/* 2nd ROW */}
            <div className="mobile-details-container">
              <div className="mobile-details">
                <div
                  className="mobile-title"
                  onClick={() => {
                    props.history.push(
                      `/browse/track/${queue.currentTrack &&
                      queue.currentTrack.track &&
                      queue.currentTrack.track.id}`
                    );
                  }}
                >
                  {queue.currentTrack.track
                    ? queue.currentTrack.track.title
                    : lastPlayedTrack.track.title}
                </div>
                <div
                  className="mobile-album"
                  onClick={() =>
                    props.history.push(
                      `/album/${queue.currentTrack.track.album.id ||
                      queue.currentTrack.track.album}`
                    )
                  }
                >
                  {queue.currentTrack.track ? (
                    <div>
                      {queue.currentTrack.track.variation ? (
                        <div>| {queue.currentTrack.track.variationTitle} </div>
                      ) : (
                        <div>
                          {queue.currentTrack.track.album.name ||
                            queue.currentTrack.track.albumName}
                        </div>
                      )}{" "}
                    </div>
                  ) : (
                    lastPlayedTrack.track.variationTitle
                  )}
                </div>
              </div>
              <div
                className={`mobile-playlist-toggle ${queue.queueTracker > 0 ? "active-playlist-button" : ""
                  }`}
                onClick={() => setMobilePlaylistVisible(!mobilePlaylistVisible)}
                ref={addtoQueuePosition}
              >
                <img
                  src={mobilePlaylistVisible ? playlistCloseMobile : playlist}
                  alt=""
                />
              </div>
            </div>
            {/* Separator */}
            <div className="separator"></div>
            {/* 3rd ROW - THE PLAYLIST_QUEUE */}

            {/* MOBILE QUEUE PANE */}
            {mobilePlaylistVisible && <QueuePane />}
          </div>
        </MobileContainer>
      )}
      {queue.playing ? (
        <audio
          ref={audioPlayerEl}
          src={
            queue.currentTrack.track
              ? `${process.env.REACT_APP_FILES_URL}/${queue.currentTrack &&
              queue.currentTrack.track &&
              encodeURIComponent(queue.currentTrack.track.mp3File)}`
              : `${process.env.REACT_APP_FILES_URL}/${lastPlayedTrack &&
              lastPlayedTrack.track &&
              encodeURIComponent(lastPlayedTrack.track.mp3File)}`
          }
          controls
          onTimeUpdate={showTime}
          onLoadedData={() => {
            const currentAudioPlayer = audioPlayerEl.current;

            currentAudioPlayer.currentTime =
              currentAudioPlayer.duration * (queue.percentagePlayed / 100);
            setCurrentTimeAndDuration();
          }}
          onEnded={playNextAndResetProgress}
          style={{ display: "none" }}
        ></audio>
      ) : (
        <audio ref={audioPlayerEl} src=""></audio>
      )}
      <DownloadModal modalState={downloadModal} />
    </>
  );
};

export default withRouter(Player);
