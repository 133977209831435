import React, { useContext } from "react";
import { Container, MenuItem } from "./styles";
import { AuthContext } from "../../context/AuthProvider";
import { withRouter } from "react-router-dom";

const landingPageUrl = process.env.REACT_APP_LANDING_PAGE_URL;

export const guestMenuItems = [
  {
    title: "Home",
    to: `${landingPageUrl}`
  },
  {
    title: "Browse",
    to: "/"
  },
  {
    title: "Playlists",
    to: "/playlists"
  },
  {
    title: "Licensing",
    to: `${landingPageUrl}/licensing`
  },
  {
    title: "Log in",
    to: "/signin"
  },
  {
    title: "Contact",
    to: `${landingPageUrl}/contact`
  }
];

export const privateMenuItems = [
  {
    title: "Projects",
    to: "/project"
  },
  {
    title: "Favorites",
    to: "/favorites"
  },

  {
    title: "Licenses",
    to: "/orders"
  },
  {
    title: "Settings",
    to: "/settings"
  },
  {
    title: "Logout",
    to: "/logout"
  },

  {
    title: "breakLine"
  },
  {
    title: "Browse",
    to: "/"
  },
  {
    title: "Home",
    to: `${landingPageUrl}`
  },
  {
    title: "Licensing",
    to: `${landingPageUrl}/licensing`
  },
  {
    title: "Contact",
    to: `${landingPageUrl}/contact`
  }
];

export const adminMenuItems = privateMenuItems.slice(0, -3).concat([
  {
    title: "Ingest Tracks",
    to: "/admin/upload"
  },
  {
    title: "Reindex Search",
    to: "/admin/reindex"
  }
]);

const menuItems = {
  guest: guestMenuItems,
  private: privateMenuItems,
  admin: adminMenuItems
};

const MenuPopover = props => {
  const auth = useContext(AuthContext);
  const role = auth.user.adminUser ? "admin" : auth.user.role;

  return (
    <Container>
      {menuItems[role].map(item => {
        if (item.title === "breakLine") {
          return <div key={item.title} className="break-line" />;
        }
        return (
          <MenuItem
            key={item.title}
            onClick={props.toggleDropdown}
            to={{ pathname: item.to, state: { refresh: true } }}
            activeClassName="active-menu-item"
            isActive={(match, location) => {
              /*
               * Logic:
               * exact match /, fuzzy prefix match other navlinks
               * browse has multiple endpoints / and /browse
               */
              return (
                (item.title === "Browse" &&
                  ["/", "/browse"].includes(location.pathname)) ||
                (item.title !== "Browse" && match !== null)
              );
            }}
            target={item.to.startsWith("http") ? "_blank" : "_self"}
          >
            <p>{item.title}</p>
          </MenuItem>
        );
      })}
    </Container>
  );
};

export default withRouter(MenuPopover);
